.room_card {

    min-height: 100px;
    height: 100%;
    background-color:white;
    cursor: pointer;
    // color:#575B5F;

    .room_number {
        // color:#575B5F;
        font-weight: bold;
        font-size: 17px;
        display: block;
        text-align: center;
        margin-top: 10px;
    }
    
    .info_text_room {
        // color:#666B6F;
        font-weight: 500;
        font-size: 12px;
    }
    .info_text_description {
        // color:#666B6F;
        font-weight: 500;
        font-size: 12px;
        display: block;
    }

    .room_actions {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        visibility: hidden;
        padding: 2px 0;
        text-align: end;

        button {
            border: none;
            font-size: 11px;
            background-color:transparent;
        }

        svg {
            // color: #5F6368;
            margin: 0 4px;
            font-size: 16px;
            cursor:pointer;
        }
    }

    &:hover {
       .room_actions {
            display: block;
        }
    }

    .circle_status {
        width: 13px;
        height: 13px;
        border-radius: 100%;
        background-color: #C26C03;
        display: inline-block;
        margin-right: 4px;
    }

}

.status_room_active {
    color:#575B5F;
    .room_number {
        color:#575B5F;
    }
    
    .info_text_room {
        color:#666B6F;
    }
}
.status_room_in_active {
    background-color:#f5222d;
    color:white;
    .room_number {
        color:#f0f0f0;
    }
    
    .info_text_room {
        color:#f0f0f0;
    }
}





