.hover-show-config-button {
    .config-button-user-access {
        display: none;
        cursor: pointer;
    }
}

.hover-show-config-button:hover {
    .config-button-user-access {
        display: inline;
    }
}

@media print{
    .class-for-print-landscape{
        size: landscape;
    }
}