.forUl {
    background-color: #0d8a71;

    & .ant-menu-title-content {
        color: #fff;
    }
    
    & .ant-menu-sub {
        background-color: #0d8a71;
        & li {
            margin: 0!important;
            padding-left: 32px!important;
            height: 35px!important;
            line-height: 35px!important;
            & .ant-menu-title-content {
                color: #fff!important;
            }
        }
    }
}
.forLang {
    margin-right: 10px;
    border-radius: 4px;
    overflow: hidden;
    & li {
        display: flex;
        align-items: center;
        justify-content: center;
        & .ant-menu-submenu-title {
            order: 2;
            padding: 0 6px!important;
            height: 15px!important;
            line-height: 15px!important;
            & .ant-menu-title-content {
                display: flex;
                align-items: center;
                justify-content: center;
                & span {
                    margin-left: 5px;
                    color: #000;
                }
            }
        }
        &.ant-menu-submenu-open {
            background-color: #fafafa;
        }
        & ul {
            &:not(.ant-menu-hidden){
                order: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                & li {
                    padding: 0 10px!important;
                    height: 15px!important;
                    line-height: 15px!important;
                    overflow: visible;
                    margin: 4px 0!important;
                    border-right: 1px solid black;
                    &:hover {
                        color: #0d8a71;
                    }
                }
            }
        }
    }
}