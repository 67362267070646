$bg_grey: #fff;

.empty_box {
    border-radius: 4px;
}

.image_box {
    background-color: $bg_grey;

    .view_box {
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.1);

        .file_actions {
            flex-direction: column;
            background-color: rgba(0, 0, 0, 0.2);
            display: none;
        }

        &:hover {
            .file_actions {
                display: flex;
            }
        }
    }

    img {
        width: 250px;
        height: 250px;
        object-fit: cover;
    }
}


.video_box {
    background-color: $bg_grey;

    .view_box {
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.1);

        .file_actions {
            flex-direction: column;
            background-color: rgba(0, 0, 0, 0.2);
            display: none;
        }

        &:hover {
            .file_actions {
                display: flex;
            }
        }
    }

    img {
        width: 250px;
        height: 250px;
        object-fit: cover;
    }
}

.audio_box {
    background-color: $bg_grey;

    // display: flex;
    // justify-content: start;
    .view_box {
        display: flex;

        .file_actions {
            flex-direction: column;
            background-color: rgba(0, 0, 0, 0.2);
            display: none;
        }

        &:hover {
            .file_actions {
                display: flex;
            }
        }
    }

    img {
        width: 250px;
        height: 250px;
        object-fit: cover;
    }
}

.file_box {
    background-color: $bg_grey;

    // justify-content: start;
    .view_box {
        display: flex;

        .file_actions {
            flex-direction: column;
            background-color: rgba(0, 0, 0, 0.2);
            display: none;
        }

        &:hover {
            .file_actions {
                display: flex;
            }
        }
    }

    .upload_box {
        img {
            width: 150px;
            height: 250px;
            object-fit: cover;
        }
    }
}