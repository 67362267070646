.student-info-card {
    width: 25%;

    .student-info-card-in {
        width: 100%;
    }
}

.student-wrapper-container {
    display: none;

    .student-info-card {
        width: 25%;

        .student-info-card-in {
            width: 100%;
        }
    }
}

@media screen and(max-width:991px){
    .student-info-card {
        width: 33.33333333%;
    
        .student-info-card-in {
            width: 100%;
        }
    }
}

@media screen and(max-width:767px){
    .student-info-card {
        width: 50%;
    
        .student-info-card-in {
            width: 100%;
        }
    }
}

@media screen and(max-width:575px){
    .student-info-card {
        width: 100%;
    
        .student-info-card-in {
            width: 100%;
        }
    }
}

@media print {
    .student-info-card {
        width: 50%;
        font-size: 14px;
        height: 215px;
        scale: 100%;
    }

    .page-break-cont {
        width: 100%;
        display: block;
        clear: both;
        page-break-after: auto !important;
    }
}
