.status_data {
    display: inline-block;
    padding: 2px 10px;
    font-size: 12px;
    min-width: 70px;
    border-radius:4px;
}
.status_data_active {
    background-color:#E6F4EA;
     color:#137333;
}
.status_data_inactive {
    background-color:#FCE8E6;
    color:#A50E0E;
}