body {
  font-family: "Source Sans Pro" !important;
}

body > iframe {
  display: none;
}

tbody button.ant-switch-checked.pending {
  background-color: #fa8c16!important;
}

.pending div {
  display: none;
}

.pending span {
  margin-right: 7px!important;
}


.ant-switch .ant-switch-inner {
  font-weight: 100!important;
  font-size: 1.02em!important;
}

tbody:not(button) :not(.ant-input-affix-wrapper)span {
  background: none !important;
  border: none !important;
  color: #0d8a72;
}

tbody button span {
  color: #fff;
  cursor: pointer!important;
}

.radButWrap .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #0d8a71!important;
}

.radButWrap .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #0d8a71;
}

.radButWrap .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}

.radButWrap .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper {
  background-color: #0d8a71!important;
  border-color: #0d8a71!important;
}

.radButWrap .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:hover {
  background-color: #0d8a71!important;
  border-color: #0d8a71!important;
}

.radButWrap .ant-radio-button-wrapper:hover {
  color: #0d8a71;
}

.tableWrap thead tr th:last-child {
  width: 150px!important;
}

.table > :not(caption) > * > * {
  background-color: transparent!important;
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: transparent!important;
}

.view_btn, .edit_btn {
  color: #81a925d0!important;
}

.view_btn:hover, .edit_btn:hover {
  color: #81a925!important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #0d8a71!important;
}

.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
  border-color: #0d8a71!important;
}

.ant-input-group-addon > {
  border-color: #0d8a71!important;
  color: #0d8a71!important;
}

.ant-input:focus, .ant-input-focused {
  border-color: #0d8a71!important;
  box-shadow: 0 0 0 2px rgb(13 138 114 / 20%)!important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #0d8a71!important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-right-color: #0d8a71!important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #0d8a71!important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #0d8a71!important;
}

.ant-radio-button-wrapper:hover {
  color: #0d8a71;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: unset;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #0d8a71;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #0d8a71;
}

.radButWrap .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff!important;
}

.ant-input {
  border-radius: 4px;
}

.ant-select-selector {
  border-radius: 4px!important;
}

.ant-input-password {
  border-radius: 4px;
}

.ant-upload {
  border-radius: 4px;
}

.ant-picker {
  border-radius: 4px;
}

.ant-pagination {
  /* border: 1px solid #0d8a71; */
  border-radius: 2.5px;
}

.ant-layout-sider-children {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
}

.ant-pagination > *:hover {
  border-color: #0d8a71!important;
}

.ant-pagination-item.ant-pagination-item-active {
  /* border: none; */
  background-color: #0d8a71;
  color: #fff;
  border: 1px solid #0d8a71!important;
}


:where(.ant-pagination-next:hover, .ant-pagination-prev:hover):not(.ant-pagination-disabled) .ant-pagination-item-link {
  color: #0d8a71!important;
  border-color: #0d8a71!important;
}

.ant-input:hover {
  border-color: #0d8a71 !important;
}

.ant-tabs-ink-bar {
  background: #0d8a71;
}

.ant-popover-message > .anticon.anticon-exclamation-circle {
  display: none!important;
}

.ant-popover-message > .ant-popover-message-title {
  padding-left: 0!important;
}

.ant-popover-buttons > :where(.ant-btn.ant-btn-default.ant-btn-sm, .ant-btn.ant-btn-primary.ant-btn-sm) {
  color: #0d8a71!important;
  background: none!important;
  border: none!important;
  margin: 0!important;
}

.ant-popover-buttons > :where(.ant-btn.ant-btn-default.ant-btn-sm, .ant-btn.ant-btn-primary.ant-btn-sm):first-child {
  color: #d31919!important;
}

.ant-modal-footer :not(.ant-btn[disabled]).ant-btn-default.ant-btn:hover,
form.ant-form :not(.ant-btn[disabled]).ant-btn-default.ant-btn:hover,
:not(.ant-btn[disabled]).ant-btn-default.ant-btn:focus {
  color: #0d8a71!important;
  border-color: #0d8a71!important;
}

.ant-popover-buttons > :where(.ant-btn.ant-btn-default.ant-btn-sm, .ant-btn.ant-btn-primary.ant-btn-sm) svg {
  width: 100%;
  height: 100%;
}

.ant-btn-link:hover, .ant-btn-link:focus {
  color: #0d8a71;
}

.ant-btn-link {
  color: #0d8a71a4;
}

.ant-menu-inline, .ant-menu-vertical {
  border: none;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 calc(50% - 23px / 2);
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.forStatistics {
  border-color: #0d8a71!important;
}

.forStatistics:hover {
  background-color: #0d8a71!important;
  border-color: #0d8a71!important;
}

.ant-popover-buttons > .ant-btn.ant-btn-default.ant-btn-sm:hover {
  border-color: #0d8a71!important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #0d8a71!important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #0d8a71!important;
  box-shadow: 0 0 0 2px rgb(13 138 114 / 20%)!important;
}

:where(.ant-col, .ant-modal-footer) :not(.ant-btn-dangerous).ant-btn-primary {
  border-color: #0d8a71!important;
  background-color: #0d8a71!important;
}

:where(.ant-col, .ant-modal-footer) :not(.ant-btn-dangerous).ant-btn-primary:hover {
  border-color: #0d8a71!important;
  background-color: #0d8a71!important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #0d8a71!important;
  box-shadow: 0 0 0 2px rgb(13 138 114 / 20%)!important;
}

.ant-picker-focused {
  border-color: #0d8a71!important;
  box-shadow: 0 0 0 2px rgb(13 138 114 / 20%)!important;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #0d8a71;
}

.ant-divider::before, .ant-divider::after {
  border-top-color: #0d8a71!important;
}

.ant-input-group-addon :where(.ant-btn:hover, .ant-btn:focus) {
  color: #0d8a71!important;
}

.ant-input-group-addon :where(.ant-btn:hover) {
  border-color: #0d8a71!important;
}

body {
  background-color: #f5f5f5!important;
}

div > .ant-tag:not(:where(td, th) .ant-tag) {
  font-size: 1.025em;
  padding: .25em;
  background: none!important;
  border: 1px #0d8a71 solid;
  color: #0d8a71!important;
  margin-right: 0!important;
}

.ant-pagination-item-link > .anticon > svg {
  vertical-align: baseline!important;
}

.table_bottom_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.excelga:hover, .excelga:focus {
  border-color: #0d8a7191!important;
  color: #0d8a71!important;
}

.excelga div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn.ant-btn-primary.ant-btn-background-ghost.me-2 {
  border-color: #0d8a7191!important;
  color: #0d8a71!important;
}

.ant-btn.ant-btn-primary.ant-btn-background-ghost.me-2:hover {
  border-color: #0d8a71!important;
}

.ant-btn.ant-btn-default.generate:hover {
  border-color: #0d8a71!important;
  color: #0d8a71!important;
}

/* .table td,
.table th {
  text-align: center;
}

.table td {
  vertical-align: middle;
  padding-top: .7em!important;
  padding-bottom: .7em!important;
} */

.crud_actions_table {
  display: inline-block!important;
  width: 100%!important;
}

.crud_actions_table svg {
  width: 1.5rem!important;
  height: 1.5rem!important;
}

.tableWrap {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}



.ant-table-thead tr th {
  background-color: rgba(13, 138, 114, 0.4);
  color: #fff !important;
}

.ant-btn-primary {
  background-color: rgba(13, 138, 114, 0.9);
}

.ant-menu-submenu-active {
  background-color: rgba(13, 138, 114, 0.1);
  color: rgba(13, 138, 114, 0.1);
}

.ant-tabs-tab-active div span {
  color: rgba(13, 138, 114, 1) !important;
  border-bottom: rgba(13, 138, 114, 1) !important;
}

.ant-tabs-tab-active::after {
  color: rgba(13, 138, 114, 1) !important;
  border-bottom: rgba(13, 138, 114, 1) !important;
}

.ant-tabs-tab-active::before {
  color: rgba(13, 138, 114, 1) !important;
  border-bottom: rgba(13, 138, 114, 1) !important;
}

.ant-tabs-tab:hover {
  color: rgba(13, 138, 114, 1) !important;
  border-bottom: rgba(13, 138, 114, 1) !important;
}

:not(.ant-col >) .ant-btn-primary {
  border-color: #0d8a71!important;
}

.ant-tooltip-inner {
  min-height: unset;
  padding-top: 0px;
  padding-bottom: 0px;
}

:not(.ant-col >) .ant-btn-primary:hover {
  background-color: rgba(13, 138, 114);
}

.ant-menu-submenu-open {
  background-color: rgba(13, 138, 114, 0.1);
}

.card {
  -webkit-box-shadow: 4px 4px 50px -19px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 50px -19px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 50px -19px rgba(34, 60, 80, 0.2);
  border: none !important;
  background-color: white;
  border-radius: 6px !important;
}

.card_shadow {
  -webkit-box-shadow: 4px 4px 50px -19px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 50px -19px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 50px -19px rgba(34, 60, 80, 0.2);
  background-color: white;
}

.card_min_height {
  min-height: 89vh;
  z-index: 1;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: #f8f9fa;
  color: var(--bs-table-striped-color);
}

form#add-role > .ant-col.ant-col-24 {
  display: flex;
  justify-content: flex-end;
}

form#add-role > .ant-col.ant-col-24 > button {
  border-color: rgba(13, 138, 114, 0.4)!important;
}

form#add-role > .ant-col.ant-col-24 > button:first-child {
  color: #0d8a71!important;
}

form#add-role > .ant-col.ant-col-24 > button:first-child:hover {
  border-color: rgba(13, 138, 114, 0.9)!important;
}

/* -----------====== this style for shadow of ui of view =======------------ */
.box-shadow {
  -webkit-box-shadow: 1px 2px 18px 2px rgba(34, 60, 80, 0.1);
  -moz-box-shadow: 1px 2px 18px 2px rgba(34, 60, 80, 0.1);
  box-shadow: 1px 2px 18px 2px rgba(34, 60, 80, 0.1);
}

/* ############ */
.d-f {
  display: flex;
  align-items: center;
}

.rounded {
  border-radius: 8px !important;
  background-color: white;
}

.btn-icon {
  position: absolute !important;
  right: 8px !important;
}

/* icons animation */

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

/* link style */
.link {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  color: #0a58ca;
  text-decoration: none;
  outline: 0;
}

/* cursor pointer className */
.cursor-pointer {
  cursor: pointer;
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* -----------------  response  ------------------- */

@media screen and (max-width: 1000px) {
  .res-1000 {
    display: none;
  }
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.inactive td {
  color: red !important;
}

.df-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* custom styles */

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.max_w_20 {
  max-width: 20px;
}

.max_w_30 {
  max-width: 30px;
}

.max_w_40 {
  max-width: 40px;
}

.max_w_50 {
  max-width: 50px;
}

.max_w_60 {
  max-width: 60px;
}

.max_w_70 {
  max-width: 70px;
}

.max_w_80 {
  max-width: 80px;
}

.max_w_90 {
  max-width: 90px;
}

.max_w_100 {
  max-width: 100px;
}

.max_w_110 {
  max-width: 110px;
}

.max_w_120 {
  max-width: 120px;
}

.max_w_130 {
  max-width: 130px;
}

.max_w_140 {
  max-width: 140px;
}

.max_w_150 {
  max-width: 150px !important;
}

.max_w_160 {
  max-width: 160px!important;
}

.max_w_170 {
  max-width: 170px;
}

.max_w_180 {
  max-width: 180px!important;
}

.max_w_190 {
  max-width: 190px;
}

.max_w_200 {
  max-width: 200px;
}

.min_w_20 {
  min-width: 20px;
}

.min_w_30 {
  min-width: 30px;
}

.min_w_40 {
  min-width: 40px;
}

.min_w_50 {
  min-width: 50px;
}

.min_w_60 {
  min-width: 60px;
}

.min_w_70 {
  min-width: 70px;
}

.min_w_80 {
  min-width: 80px;
}

.min_w_90 {
  min-width: 90px;
}

.min_w_100 {
  min-width: 100px;
}

.min_w_110 {
  min-width: 110px;
}

.min_w_120 {
  min-width: 120px;
}

.min_w_130 {
  min-width: 130px;
}

.min_w_140 {
  min-width: 140px;
}

.min_w_150 {
  min-width: 150px;
}

.min_w_160 {
  min-width: 160px;
}

.min_w_170 {
  min-width: 170px;
}

.min_w_180 {
  min-width: 180px;
}

.min_w_190 {
  min-width: 190px;
}

.min_w_200 {
  min-width: 200px;
}

.w_20 {
  width: 20px;
}

.w_30 {
  width: 30px;
}

.w_40 {
  width: 40px;
}

.w_50 {
  width: 50px;
}

.w_60 {
  width: 60px;
}

.w_70 {
  width: 70px;
}

.w_80 {
  width: 80px;
}

.w_90 {
  width: 90px !important;
}

.w_100 {
  width: 100px;
}

.w_110 {
  width: 110px;
}

.w_120 {
  width: 120px !important;
}

.w_130 {
  width: 130px;
}

.w_140 {
  width: 140px!important;
}

.w_150 {
  width: 150px;
}

.w_160 {
  width: 160px!important;
}

.w_170 {
  width: 170px;
}

.w_180 {
  width: 180px;
}

.w_190 {
  width: 190px;
}

.w_200 {
  width: 200px;
}

.w_250 {
  width: 250px;
}

.w_300 {
  width: 300px;
}

.w_350 {
  width: 350px;
}

.w_400 {
  width: 400px;
}

.vertical_align_middle {
  vertical-align: middle;
}

.ant_input_custom {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.ant_input_custom:placeholder-shown {
  text-overflow: ellipsis;
  font-size: 13px;
}

.ant_textarea_custom {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.ant_textarea_custom:placeholder-shown {
  text-overflow: ellipsis;
  font-size: 14px;
}

.ant-select_custom {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 13px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

th .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 28px !important;
  padding: 0 11px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 26px;
  transition: all 0.3s;
}
tbody .ant-select-single .ant-select-selector .ant-select-selection-item,
tbody .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  flex: none;
}
.wp-25 {
  width: 25%!important;
}
.wp-75 {
  width: 75%!important;
}

.ant-btn:disabled{
  /* background-color: rgba(13, 138, 113, 0.3) !important; */
  /* color: rgba(13, 138, 113); */
  /* border-color: rgba(13, 138, 113, 0) !important; */
  background-color: #dfdfdf !important;
  color: #a5a5a5;
  border-color: #b4b4b4 !important;
}
.ant-btn:disabled:hover{
  background-color: #dfdfdf !important;
  color: #a5a5a5;
  border-color: #b4b4b4 !important;
}
