#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}

.ant-menu-light .ant-menu-submenu-title:hover {
    color: rgb(52, 157, 137)!important;
}

.ant-menu-submenu-inline > .ant-menu-submenu-active {
    :hover{
        color: rgb(52, 157, 137) !important;
    }
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    text-decoration: none!important;
    color: rgb(52, 157, 137)!important;
}

.ant-menu-item a {
    text-decoration: none!important;
    color: rgb(52, 157, 137)!important;
}

.ant-menu-item a:hover {
    color: rgb(52, 157, 137)!important;
}

.ant-menu-item-selected {
    border-right: rgb(13, 138, 114) !important;
    background-color: rgba(13, 138, 114, .1) !important;
}

.ant-menu-item::after {
    border-right: 2px solid rgb(13, 138, 114) !important;
}

.layout-header-style {
    padding: 0;
    background-color: rgb(13, 138, 114);
    line-height: 50px;
}

.collape-sidebar-button {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.collape-sidebar-button:visited {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.collape-sidebar-button:active {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.collape-sidebar-button:hover {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.collape-sidebar-button:focus {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.sidebar-scroll-style {
    &.ant-menu-inline-collapsed {
        &::-webkit-scrollbar-track {
            background-color: rgb(13, 138, 114);
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgb(13, 138, 114);
        }
        &::-webkit-scrollbar-thumb:hover {
            background-color: rgb(13, 138, 114);
        }
    }
}

.sidebar-scroll-style {
    &::-webkit-scrollbar {
        height: 2px;
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background-color: rgb(245, 245, 245);
        border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(224, 224, 224);
        border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgb(224, 224, 224);
    }

}

.ant-menu-submenu-arrow {
    display: none;
}

.content_card {
    padding: 12px;
    background-color: #F5F7F9;
    overflow: auto;
    // position: relative;
    height: calc(100vh - 55px);

    &::-webkit-scrollbar {
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background-color: rgb(245, 245, 245);
        border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(224, 224, 224);
        border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgb(207, 207, 207);
    }

    & > div {
        // background-color: white;
        border-radius: 2px;
        height: auto;
        // min-height: calc(96vh - #{$header_height});
    }
}