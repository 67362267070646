.table_head_c {
    .ant-input-affix-wrapper {
        padding: 4px 8px;
        font-size: 13px;
        line-height: 1.5715;
        background-color: #fff;
        border-radius: 3px;
    }

    .ant-input {
        padding: 4px 11px;
        font-size: 13px;
        line-height: 1.5715;
        background-color: #fff;
        border-radius: 2px;
    }
}

.ant-divider-inner-text {
    font-size: 14px;
}

.select__pin::selection {
    background-color: #000;
    color: #fff;
}

.export_student {
    width: 100%;
    height: 100vh;

    .ant-modal {
        top: 0 !important;
        max-width: 100% !important;
        padding: 0!important;
        .ant-modal-content {
            width: 100%;
            height: 100vh;
        }
    }
}
.dd_student {
    width: 100%;
    height: 100vh;

    .ant-modal {
        top: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
        height: 100% !important;
        margin: 0;
        background-color: grey !important;
        .ant-modal-content {
            width: 100%;
            height: 100vh !important;
        }
    }

    .content_box {
        overflow-y: auto;
        height: 80vh;
        margin-top: 4px;
        user-select: none;

        // &::-webkit-scrollbar {
        //     width: 10px;
        //     background-color: blue;
        // }
        // &::-webkit-scrollbar-thumb {
        //     background-color: yellow;
        // }
    }
}

.dormitroy_petition {
    background-color: #e8f0fe;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 5px 10px;
    display: block;
    border-left: 4px solid #1967d1;
}

.dormitory_file_list {
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    width: 100%;
    margin: 20px 0;
    border-radius: 4px;
    padding: 4px 10px;

    .title {
        font-size: 14px;
        font-weight: 400;
        color: #5f6368;
        border-bottom: 1px solid #dadce0;
        padding-bottom: 4px;
        display: block;
    }
}

.user_profile_top_box {
    img {
      width: 160px;
      height: 200px;
    }

    .info_box_top {
      width: calc(100% - 150px);
      padding: 0 4px 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // margin-top: 10px;

      p {
        color: #687079;
        text-shadow: 0 0 #687079;
        font-size: 14px;
        letter-spacing: 0.5px;
      }

      .login_password {
        font-size: 14px;
        display: flex;
        justify-content: space-between;

        span {
          font-size: 13px;
          border: 1px solid #e9ecef;
          padding: 2px 4px;
          min-width: 40%;
          display: inline-block;
          border-radius: 4px;
          vertical-align: middle;
          color: #7a7f85;
          text-shadow: 0 0 #7a7f85;
          text-align: center;
        }

        button {
          background-color: #7f55da;
          color: white;
          border: none;
          border-radius: 4px;
        }
      }
      .user_status {
        text-align: center;
        border-radius: 4px;
        font-size: 10px;
        color: white;
        text-transform: uppercase;
        position: absolute;
        top: 3px;
        right: 3px;
        padding: 1px 4px 0px 4px;
      }
    }
  }

  .info_profile_key {
    color: #9a9a9a;
    font-size: 12px;
    letter-spacing: 0.5px;
  }
  .info_profile_value {
    color: #73787e;
    font-size: 13px;
    text-shadow: 0 0 #73787e;
    letter-spacing: 0.4px;
  }

  .info_profile_phone_key {
    color: #9a9a9a;
    font-size: 13px;
    text-shadow: 0 0px #9a9a9a;
    letter-spacing: 0.5px;
    position: relative;
    margin-right: 6px;

    & > span {
      position: absolute;
      top: -4px;
      left: 8px;
      font-size: 10px;
      color: #c26c03;
      text-shadow: 1px 0 #c26c03;
    }
  }
