/*
**  Author        : Chafik Amraoui
**  Dtae          : 09/10/2021
**  website       : Github.com/chafikamr
**  design source : https://elzero.org/frontend-google-colors-animation/
*/


/* global rules */

.login_loader {
    display: grid;
    place-items: center;
    background-color: #fff;
    width: 100%;
    height: 100vh;

    & .continuous-1 {
        width: 300px;
        height: 80px;
        background-image: linear-gradient(90deg, #0d8a719c 33%,#0d8a71 50%, #0d8a719c 66%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-weight: 600;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size:300% 100%;
        letter-spacing: 3px;
        animation: ct1 1s infinite linear;
    }
  
    @keyframes ct1 {
        0% {background-position: right}
    }
}