.button_create {
    border: none;
    font-size: 15px;
    font-weight: 400;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #0d8a7191!important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0d8a71!important;

    &:hover {
        border-color: #0d8a71!important;
    }

    &:active {
        border-color: #0d8a71!important;
    }

    &>svg {
        color: #fff!important;
    }
}
.button_export {
    border: none;
    font-size: 15px;
    font-weight: 400;
    background-color: white;
    padding: 4px 14px;
    border-radius: 3px;
    color: #198754;
    border: 1px solid #DADCE0;
    display: inline-block;

    &:hover {
        background-color: #f8f8f8;
        border: 1px solid #f8f8f8;
    }

    &:active {
        background-color: #EDF2F9;
        border: 1px solid #EDF2F9;
    }

    &>svg {
        color: #198754;
        margin-bottom: 1px;
    }
}

.button_confirm {
    border: none;
    font-size: 15px;
    font-weight: 400;
    background-color: white;
    padding: 4px 14px;
    border-radius: 3px;
    color: #1A73E8;
    border: 1px solid #DADCE0;
    display: flex;
    align-items: center;

    &:disabled {
        background-color: #F5F5F5;
        pointer-events: none;
        touch-action: manipulation;
        user-select: none;
    }

    &:active {
        background-color: #EDF2F9;
        border: 1px solid #EDF2F9;
    }

    &>svg {
        color: #1A73E8;
    }

}