.not_user_select{
  font-family: Rubik, Helvetica, Arial, serif !important;
}

.acardion_panel_custom {
  margin-top: 10px;
  border-radius: 6px;
  background-color: white;
  border: none !important;
  &:hover {
    background-color: #fafafa;
  }

  .ant-collapse-arrow {
    vertical-align: -6px !important;
  }
}

.sdsdsd {
  border: none !important;
  background-color: white;
}

.child_add_btn {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
}

.time_table_list {
  width: 100%;
  background-color: white;
}

.time_table_collabse_panel_text {
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  & > span {
    font-weight: 450;
    color: #434343;
  }
}

.tine_table_accordion_title {
  display: flex;
  align-items: center;
  color: #262626;
  font-weight: 400;
  & > svg {
    margin-right: 10px;
    margin-left: 4px;
    color: #1890ff;
  }
}

.collapse_icon_custom {
  transform: rotateX(90deg);
  transition: all 0.2s ease-in-out;
}

.timetable_table_actions {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > svg {
    cursor: pointer !important;
  }

  .view_btn_tm {
    font-size: 16px;
    color: #595959 !important;
  }
  .delete_btn_tm {
    font-size: 13px;
    color: #ff4d4f !important;
  }
  .edit_btn_tm {
    font-size: 14px;
    color: #595959 !important;
  }

  .arrow_btn_tm {
    font-size: 22px;

    &:hover {
      color: #1890ff;
    }
  }
  .plus_btn_tm {
    font-size: 22px;
    color: #52c41a;
  }
}

.crud_actions_tablechild {
  & > svg {
    cursor: pointer;
  }

  .view_btn_tm_child {
    color: #595959;
    font-size: 16px;
  }
  .delete_btn_tm_child {
    color: #ff4d4f;
    font-size: 13px;
    margin-left: 10px;
  }
}


.time_lessons {
  color: '#5F6368'
}