.ant-collapse-content-box {
  padding: 0 !important;
}

.time_option_collapse_header {
  width: 100%;

  .title {
    font-size: 14px;
    font-weight: 500;
    color: #5f6368;
  }

  hr {
    background-color: #dadce0;
  }

  .ant-input-affix-wrapper {
    padding: 3px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 13px;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 166px;
    max-width: 150px;
  }

  .ant-upload-list-picture-card-container {
    display: inline-block;
    width: 100%;
    height: 166px;
    max-width: 150px;
    margin: 0 8px 8px 0;
  }

  .ant-input {
    padding: 3px 10px;
    color: #000;
    font-size: 13px;
    line-height: 1.5715;
    background-color: #fff;
    border: 1px solid #dadce0;
    border-radius: 4px;
  }

  .ant-select {
    font-size: 13px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 28px !important;
    padding: 0px 10px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
}

.fgfg {
  &:hover {
    background-color: grey;
    cursor: pointer;
  }

  & > div {
    background-color: #000;
    width: 100% !important;
    height: 20px !important;
  }
}

.atrend_student_popover {
  display: flex;
  justify-content: space-between;
  position: relative;
  & > span {
    position: absolute;
    color: #c26c03;
    font-weight: 500;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
  }
  button:nth-child(1) {
    border: none;
    background-color: green;
    border-radius: 2px;
    color: white;
    width: 80px;
    transition: all 0.1s ease-in-out;
    user-select: none;

    &:active {
      background-color: #006500;
    }
  }
  button:nth-child(2) {
    border: none;
    background-color: red;
    border-radius: 2px;
    color: white;
    width: 80px;
    transition: all 0.1s ease-in-out;
    user-select: none;

    &:active {
      background-color: #c10000;
    }
  }
}

.to_attend_table {
  width: 95%;
  overflow-x: scroll;
  table {
    // overflow: scroll;
    // border-collapse: collapse;
    table-layout: fixed;
    width: 1600px;

    thead, tbody, tfoot, tr, td, th {
      width:200px;
    }

    th:nth-child(1) {
      position: sticky;
      left: 0;
      background:inherit;
      width: 20px;
      height: 40px;
      z-index: 10;
    }
    td:nth-child(1) {
      position: sticky;
      left:0;
      background:inherit;
      width: 20px;
      height: 40px;
      z-index: 10;
    }
    th:nth-child(2) {
      position: sticky;
      left: 34px;
      background:inherit;
      width: 400px;
      color: grey;
      height: 40px;
      z-index: 10;
      background-color:white;
    }
    td:nth-child(2) {
      position: sticky;
      left:34px;
      background:inherit;
      width: 400px;
      color: grey;
      height: 40px;
      z-index: 10;
      box-shadow: 5px 0 3px -2px  black;
      background-color:white;
    }
  }
}
