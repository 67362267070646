.time_lesson {
    color: #5f6368;
    font-weight: 500;

    &:hover {
        color: #1890ff;
        text-decoration: underline;
        cursor: pointer;
    }
}

.time_table_title {
    background-color: #f1f3f4;
    display: flex;
    justify-content: space-between;
    padding: 10px 14px;
    border-radius: 4px;
}

.time_table_add_btn {
    border-radius: 50%;
    border: none;
    background-color: #e8f0fe;
    color: #1967d2;
    width: 26px;
    height: 26px;
    text-align: center;
}

.created_time_table_box {
    display: block;
    padding: 12px 6px 4px 6px;
    margin-top: 10px;
    border: 1px solid #eff1f2;
    border-radius: 4px;
    position: relative;
    text-align: start;
    &:hover {
        // background-color: #eff1f2;
        border-radius: 4px;
        cursor: pointer;
    }

    p {
        margin: 0;
        font-weight: 400;
    }

    .dot_simvol {
        display: block;
        position: absolute;
        top: -2px;
        right: -2px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: bold;
        padding: 0px 3px 0px 3px;
        // width: 12px;
        background-color: #1890ff;
        color: white;
    }

    .info_text_subject {
        // color: #878d92;
        color: #5f6368;
        font-size: 12px;
        font-weight: 500;

        & > span {
            color: #202124;
            display: block;
        }
    }
    .info_text_teacher {
        color: #c26c03;
        font-size: 12px;
        font-weight: 500;
        text-transform: capitalize;
    }
}
.parent_time_table_box {
    display: block;
    padding: 8px 6px 4px 6px;
    margin-top: 4px;
    border: 1px solid #eff1f2;
    border-radius: 4px;

    i {
        color: blue;
    }

    .info_text_subject {
        // color: #878d92;
        color: #5f6368;
        font-size: 13px;
        font-weight: 500;

        & > span {
            color: #202124;
            display: block;
        }
    }
    .info_text_teacher {
        color: #c26c03;
        font-size: 13px;
        font-weight: 500;
    }
}

.view_text {
    color: #5f6368;
    font-size: 13px;
    font-weight: 500;
}

.modal_title {
    color: #dde0e6;
    font-weight: 500;
    font-size: 14px;

    & > span {
        color: #f1f3f4;
        margin-right: 10px;
        display: inline-block;
    }
}

.variant_time_table_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f3f4;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    & > span {
        font-weight: bold;
        color: #5f6368;
        display: block;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: 14px;
    }
}

