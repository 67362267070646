.mesages_container {
    height: 600px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 4px;
    }

    @media (max-width: 786px) {
        height: max-content;
        overflow-y: none;
    }
}


.message_box {
    display: flex;
    justify-content: space-between;

  
    & > div:nth-child(2) {
        width: calc(100% - 280px);
        & > textarea {
            width: 100%;
            height: 100px;
            padding: 6px 10px;
            border-radius: 4px;
            border-color: #d9d9d9;

            &:focus {
                outline: #595959;
            }
        }
    }

    @media (max-width: 786px) {
        display: block;
        & > div:nth-child(2) {
            width: 100%;
            margin-top: 20px;
            & > textarea {
                width: 100%;
                height: 100px;
                padding: 6px 10px;
                border-radius: 4px;
                border-color: #d9d9d9;

                &:focus {
                    outline: #595959;
                }
            }
        }
    }
}
