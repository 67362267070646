.show-student-exams-page-style {
    color: rgba($color: blue, $alpha: .8) !important;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.question_text_check {
    font-size: 14px;
    color: #434343;
}
.question_part_check {
    font-size: 14px;
    color: #434343;
    background-color: white;
    text-align: justify;
    display: block;
}


.question_text_area {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-align: justify;
    cursor: pointer;
    font-size: 14px;
}
.question_text_area_full {
    text-align: justify;
    font-size: 14px;
}

[data-theme='compact'] .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 16px;
  overflow: hidden;
  background: white;
  border: 0px;
  border-radius: 2px;
  font-size: 14px;
}

.ant-collapse-arrow {
    color:#096DE0;
}


.rotate_arrow_top {
    transform: rotate(180deg);
    margin-bottom: 3px;
}
.rotate_arrow_bottom {
    transform: rotate(0deg);
    margin-bottom: 3px;
}


.title_pdf_answers {
    text-align: justify;
    color:red;
}


.student_exam_time_detail {
    display: flex;
    justify-content: space-around;

    & > span {
        width: calc(100% / 4);
        display: block;
    }
}


.exam_student_ball_custom {
    .ant-form-item-label {
        padding: 0 !important;
    }
}


.appelation_text {
    font-size: 14px;
    color:#434343;
    text-align: justify;
    display: inline-block;
}

.appelation_card {
        // -webkit-box-shadow: -3px 4px 24px -5px rgba(34, 60, 80, 0.2);
        // -moz-box-shadow: -3px 4px 24px -5px rgba(34, 60, 80, 0.2);
        // box-shadow: -3px 4px 24px -5px rgba(34, 60, 80, 0.2);
        box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.appelation-collapse-custom {

    background-color: white;
   
    .ant-collapse-header {
        padding: 6px 10px !important;
    }
    
  }

.appelation-collapse-custom-panel {
    margin-bottom: 10px;
    overflow: hidden;
    background: #FBFBFB;
    border: 0px; 
    border-bottom: 0px !important;
    user-select: none;
  }

.appelation_exam_table {
    thead, tbody, tfoot, tr, th {
        border: 1px solid transparent !important;
        padding: 5px 10px;
      }
     td {
        border-left: none !important;
        border-right: none !important;
        border-bottom: 1px solid #F7FAFE !important;
        padding: 5px 10px;
      }

      .th_text {
        font-size:13px;
        color:#8c8c8c;
        font-weight: 450;
      }
      
}