.custom-user-avatar-container {
  display: flex;
  justify-content: center;

  .custom-user-avatar-conf {
    position: relative;
    overflow: hidden;
    border-radius: 50%;

    .custom-user-avatar-upload {
      overflow: hidden;
      display: flex;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      z-index: 100;
      left: 0;
      right: 0;
      color: white;
      transition: margin-top .3s linear;
    }

    &:hover {
      .custom-user-avatar-upload {
        margin-top: -55px;
      }
    }
  }
}