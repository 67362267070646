.time_table_week_name {
    background-color: #f1f4f9;
    color: #5f6368;
    padding: 4px 10px;
    font-weight: bold;
    font-size: 14px;
}
.time_table_title {
    background-color: #e8f0fe;
    display: flex;
    justify-content: space-between;
    padding: 4px 14px;
    border-radius: 4px;
    // margin-top: 10px;
    color: #1967d2;

    .time_lesson {
        color: #5f6368;
        font-weight: 500;
        color: #1967d2;
        font-size: 13px;
    }
}

.created_time_table_box {
    display: block;
    padding: 4px 6px 4px 6px;
    margin-top: 6px;
    border: 1px solid #eff1f2;
    border-radius: 4px;
    position: relative;
    &:hover {
        border-radius: 4px;
        cursor: pointer;
    }

    .remove_time_table {
        display: block;
        position: absolute;
        top: -2px;
        right: -2px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: bold;
        padding: 0px 3px 0px 3px;
        background-color: #fce8e6;
        color: #d93025;
        border: none;
    }

    .info_text_subject {
        color: #5f6368;
        font-size: 12px;
        font-weight: 500;

        & > span {
            color: #202124;
            display: block;
        }
    }
    .info_text_teacher {
        color: #c26c03;
        font-size: 12px;
        font-weight: 500;
        text-transform: capitalize;
    }
}

.subject_containers {
    padding: 10px 0;
    margin: 0;
    width: 100%;
    & > a {
        border: 1px solid #dadce0;
        border-radius: 10px;
        padding: 4px 18px;
        font-size: 13px;
        font-weight: 500;
        color: #5f6368;
        font-style: italic;
        margin-right: 10px;
        text-decoration: none;
        margin-top: 8px;
        display: inline-block;

        &:hover {
            border: 1px solid #1c91fe;
            color: #1c91fe;
        }
    }
}

.time_table_card {
    border: 1px solid #f1f4f9;
    padding: 2px 4px;
    position: relative;
    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f1f4f9;
        span {
            font-size: 13px;
            font-weight: 450;
            color: #000;
        }
    }
    & > p {
        font-size: 13px;
        margin-top: 2px;
    }

    .selected_count {
        position: absolute;
        right: 2px;
        bottom: 0;
        font-size: 11px;
        background-color: #c26c03;
        border-radius: 4px;
        color: white;
        padding: 0px 6px 0 6px;
    }
}

.filtered_time_table_card {
    border: 1px solid #1e92fd;
    border-radius: 4px;
    padding: 6px;
}

.parent_time_table_card {
    cursor: pointer;
}

.selectible_time_table_container {
    background-color: #eff2f4;
    padding-bottom: 14px;
    margin-bottom: 14px;
    height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;

    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #bfbfbf;
        border-radius: 4px;
    }

    .time_table_variant_header {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        width: 100%;
        border-radius: 0 !important;
        font-size: 20px;
        border: 1px solid grey;
        z-index: 1;
        margin-bottom: 16px;
    }
}

.link_variant_card {
    text-decoration: none;
    .time_table_variants {
        display: flex;
        justify-content: space-between;

        &:hover {
            & > span:nth-child(1) {
                color: #1c92ff;
            }
        }

        & > span:nth-child(1) {
            text-transform: uppercase;
            font-weight: 500;
            color: #1d1e19;
            font-size: 16px;
        }

        .selected_count {
            font-size: 12px;
            border-radius: 4px;
            color: white;
            padding: 2px 6px 1px 6px;
            height: 100%;
            margin-right: 6px;
            text-decoration: none;
        }
    }
}

.time_table_subjects {
    & > span {
        text-transform: uppercase;
        font-weight: 500;
        color: #1d1e19;
        font-size: 14px;
        display: block;
        border-bottom: 1px solid #dadce0;
        padding: 0 0 10px 0;
    }
}

.time_table_select_card {
    border: 1px solid #dadce0;
    border-radius: 4px;
    margin-top: 10px;
    padding: 2px 6px;

    svg {
        color: #737373;
        font-size: 16px;
        margin-right: 10px;
    }

    & > span {
        display: block;
        font-size: 13px;
        font-weight: 400;
    }

    span:nth-child(1) {
        color: #5f6368;
    }
    span:nth-child(2) {
        color: #5f6368;
    }
    span:nth-child(3) {
        color: #c26c03;
        font-weight: 450;
    }
}

.notice_time_table {
    width: 100%;
    border-radius: 4px;
    background-color: #eff2f7;
    padding: 6px 10px;
    font-size: 14px;
    & > hr {
        background-color: #b5b7bb;
    }
}


