@import "../../../../../../assets/scss_css/variables.scss";

.ripple-button {
  height: 30px;
  border-radius: 6px;
  border: none;
  background: transparent;
  color: #fff;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 0;
  transition: 0.4s;
  width: max-content;
}

.ripple-button > .ripple {
  width: 25px;
  height: 25px;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  display: block;
  content: "";
  border-radius: 9999px;
  opacity: 1;
  animation: 0.9s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}

.ripple-button > .content {
  position: relative;
  z-index: 2;
}

.header_language {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 2px 10px;
  height: 34px;
  width: 100px;

  @media (max-width: 700px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
    height: 28px;
    width: 70px;
    & > span {
      font-size: 12px;
    }
  }
}

.header_language_img {
  width: 18px;
  height: 10px;
}

.header_dilator_icon {
  @media (max-width: 700px) {
    display: none;
  }
}
