.field_set_box {
    border: 1px solid #eff1f4 !important;
    padding: 0 8px !important;
    border-radius: 4px;

    & > legend {
        float: none;
        padding: 0 4px;
        color: #9a9a9a;
        // color: #1890FF;
        // color: #c26c03;
        margin: 0;
        font-size: 11px;
    }

    & > span {
        color: #73787e;
        font-size: 13px;
        text-shadow: 0 0 #73787e;
        letter-spacing: 0.4px;
    }
}
