.permissions:hover{
  background-color: rgba(0,0,0,0.04);
  box-shadow: 0 0 3px 0 rgba(0,0,0,0.4);
}

.permissions{
  border-bottom: .01em solid rgba(0,0,0,0.1);
  div.d-flex{
    .actions{
      padding: 5px;
      margin:0;
      div{
        width: 100%;
      }
      div:first-child{
        input{
          display: inline;
        }
        width: 22%;
        display: inline-block;
      }
      div:last-child{
        width: 78%;
        display: inline-block;
      }
    }

  }
}



@media screen and(max-width: 1100px){
  .permissions{
    border-bottom: .01em solid rgba(0,0,0,0.1);
    div.d-flex{
      .actions{
        padding: 5px;
        margin:0;
        div{
          width: 100%;
        }
        div:first-child{
          input{
            display: inline;
          }
          width: 22%;
          display: block;
        }
        div:last-child{
          width: 78%;
          display: block;
        }
      }

    }
  }
}

