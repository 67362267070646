/** @format */

body {
  margin: 0;
  padding: 0;
  background-color: #f8f8fb;
  font-family: Calibri, Roboto, Helvetica, Arial, sans-serif !important;
}

.ant-divider {
  &::before,
  &::after {
    border-top-color: rgba(50, 50, 50, 0.75) !important;
  }

  & > .ant-divider-inner-text > h5 {
    margin-bottom: 0;
  }
}

div[role="document"].ant-modal > .ant-modal-content {
  overflow: hidden;
  border-radius: 10px;

  & > .ant-modal-close > .ant-modal-close-x {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > .ant-modal-header {
    background-color: #0d8a71;

    & > .ant-modal-title {
      color: #fff;
    }
  }
}

.not_user_select {
  user-select: none;
}

.table-row-hover:hover {
  background: rgba($color: #000000, $alpha: 0.1);
  box-shadow: 0 5px 5px 0 rgba($color: #000000, $alpha: 0.1);
}

.table-row-cursor-pointer {
  cursor: pointer;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: #f8f9fa !important;
  color: var(--bs-table-striped-color) !important;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

user agent stylesheet tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.table_body_c,
tbody {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #000;
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-bg: #fafafa !important;
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: #f5f5f5 !important;
  margin-bottom: 1rem;
  color: #212529;
}

// this style for some action buttons on the table right part of table
.crud_actions_table {
  & > svg {
    cursor: pointer;
  }
  .delete_btn {
    color: #ff4d4f;
    font-size: 14px;
    margin: 0 6px;
    &:hover {
      color: #f5222d;
    }
  }
  .view_btn {
    color: #595959;
    font-size: 16px;
    margin: 0 6px;
    &:hover {
      color: #262626;
    }
  }
  .edit_btn {
    color: #595959;
    font-size: 16px;
    margin: 0 6px;
    &:hover {
      color: #262626;
    }
  }
}

// this style for different pages of content for example like this ( <- Page Name )
.pageContentTitle {
  display: flex;
  align-items: center;
  & > span {
    font-weight: 500;
    font-size: 15px !important;
    color: #5f6368;
    margin-left: 16px;
    display: block;
  }
  & > svg {
    font-size: 14px;
    cursor: pointer;
    color: #595959;
    &:hover {
      color: #5a8dee;
    }
  }
}

.pageContentTitleStudent {
  display: flex;
  align-items: center;
  margin-top: 20px;
  & > span {
    font-size: 15px;
    font-weight: 400;
    margin-left: 10px;
    display: block;
  }
  & > svg {
    font-size: 22px;
    cursor: pointer;
    color: #595959;
    &:hover {
      color: #1890ff;
    }
  }
}

// this style for avatar on the table
.custom_vatar_box {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  & > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
}

.custom_user_img_detail {
  width: 100%;
  height: 400px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    vertical-align: middle;
  }
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 786px) {
  .ant-tooltip {
    display: none !important;
  }
}

.loader-line {
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 0 auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #0d8a71d0;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

.goBackBtn {
  font-size: 15px;
  margin-right: 14px;
  color: #595959;
  cursor: pointer;
  margin-bottom: 2px;
  &:hover {
    color: #096dd9;
  }
}

// <-------======styles for Form =====---------->
.form_ui_flex {
  display: flex;
  @media (max-width: 576px) {
    display: block;
  }
}

.form_ui_flex_form_item {
  width: calc(100% - 168px);
  @media (max-width: 576px) {
    width: 100%;
  }
}

// <-------====== Table style start =======---------->

.table_head_c,
thead {
  font-size: 13px !important;
  font-weight: 450 !important;
}

.table_body_c,
tbody {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #000;
}

.table_bottom_footer {
  display: flex;
  justify-content: space-between;

  @media (max-width: 786px) {
    display: block;
    text-align: center;
    & > div:nth-child(1) {
      margin-bottom: 10px;
    }
  }
}

// <-------====== Table style end =======---------->

// personal info view start
.info_view_box {
  -webkit-box-shadow: 0px 0px 12px -4px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 12px -4px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 12px -4px rgba(34, 60, 80, 0.2);
  border-radius: 6px;
}

.info_text_key {
  font-size: 15px;
  font-weight: 400;
  color: #a6a6a6;
  margin-right: 10px;
}

.info_text_value {
  font-size: 13px;
  font-weight: 400;
  color: #434343;
}

.view_key {
  color: #878d92;
  font-size: 14px;
  font-weight: 400;
}
.view_value {
  color: #4d5256;
  font-size: 14px;
  font-weight: 400;
}

.info_fio {
  font-size: 15px;
  font-weight: 450;
  color: #495057;
  margin-left: 15px;
  width: 100%;
}

.info_view_description {
  margin: 4px auto;
  border-radius: 4px;
  background-color: #fafafa;
  font-size: 13px;
  font-weight: 400;
  color: #434343;
  padding: 3px 6px;
  min-height: 50px;
}

.info_status {
  width: max-content;
  border-radius: 2px;
  font-size: 11px !important;
  color: whitesmoke;
  padding: 0 6px;
  float: right;
  font-weight: 300;
}

.info_status1 {
  width: max-content;
  border-radius: 2px;
  font-size: 11px !important;
  color: whitesmoke;
  padding: 0 6px;
  font-weight: 300;
}

.info_title_text {
  font-size: 14px;
  font-weight: 500;
}

// personal info view end

// links

.fio_link {
  text-decoration: none;
  // color: #4B4E52;
  color: #000;
  transition: 0.1s;
  text-transform: uppercase;
  // font-family: "Product Sans",Arial,sans-serif !important;
  // font-size: 12px !important;
  // font-weight: 450 !important;
  cursor: pointer;
  &:hover {
    color: #0d83fb;
    text-decoration: underline;
  }
}

// input icon buttons

.input_addon_icon_box {
  .ant-input-group-addon {
    position: relative;
    padding: 0 0px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 30px;
    transition: all 0.3s;
  }
}

.ant-upload {
  width: 100%;
}

// ----------- table style ----------------------
.for-table {
  border: 1px solid rgba(13, 138, 114, 0.1) !important;

  thead,
  tbody {
    border: none !important;
  }

  thead {
    font-weight: 700 !important;
    letter-spacing: 0.5px !important;
    word-spacing: 3px;
  }

  tbody :where(button span, button, button div) {
    opacity: 1 !important;
  }

  tbody,
  tbody span {
    font-weight: 600 !important;
    color: #434343;
  }

  tbody tr td:not(:last-child) a {
    text-decoration: none !important;
    font-weight: 600 !important;
    background: white;
    color: rgba(13, 138, 114, 0.7);
    border: 1px solid rgba(100, 183, 178, 0.5) !important;
    border-radius: 3px !important;
    padding: 2px 10px 2px 10px;
  }

  tbody tr td:not(:last-child) a:hover {
    color: rgba(13, 138, 114, 0.7) !important;
    text-decoration: underline !important;
  }

  tbody td:last-child a {
    text-decoration: none !important;
  }

  .ant-switch .ant-switch-inner {
    font-weight: 100 !important;
    font-size: 1.02em !important;
  }

  tbody tr:not(.searchBar) span:not(.ant-switch-inner) {
    background: none!important;
    border: none!important;
    color: #0d8a72;
  }

  tbody button span {
    color: #fff!important;
    cursor: pointer !important;
  }

  td:not(a) {
    color: #47756b !important;
  }

  // --------------------------------------------

  th .ant-divider {
    background-color: #fff !important;
  }

  .ant-select-selection-placeholder {
    font-weight: 300 !important;
  }

  th {
    vertical-align: text-top !important;
    font-size: 1.1em;
  }

  th .ant-input-affix-wrapper {
    border-radius: 5px !important;
    height: 26px !important;
    padding: 4px 6px;
  }

  th .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 26px !important;
    border-radius: 5px !important;
  }

  th .ant-select {
    border-radius: 5px !important;
    overflow: hidden;
  }

  th .ant-input-affix-wrapper input::placeholder,
  th .ant-select-selection-placeholder {
    text-align: left !important;
    font-weight: 600 !important;
    color: rgba(13, 138, 114, 0.8);
    letter-spacing: 0.5px;
  }

  .ant-switch.ant-switch-small.ant-switch-checked {
    background-color: #81a925d0 !important;
  }

  td,
  th {
    text-align: center;
  }

  td {
    vertical-align: middle;
    padding-top: 0.7em !important;
    padding-bottom: 0.7em !important;
  }

  // --------------------------------------------------

  tbody tr:not(:last-child) td {
    /* border-bottom: 0.5px solid rgba(13, 138, 114, 0.2) !important; */
    border-bottom: 0.5px solid rgba(13, 138, 114, 0.1) !important;
  }

  td:not(:last-child) {
    /* border-right: 0.5px solid rgba(13, 138, 114, 0.2) !important; */
    border-right: 0.5px solid rgba(13, 138, 114, 0.1) !important;
  }
  td:not(:first-child) {
    /* border-left: 0.5px solid rgba(13, 138, 114, 0.2) !important; */
    border-left: 0.5px solid rgba(13, 138, 114, 0.1) !important;
  }

  tbody tr:nth-child(2n) {
    background-color: rgba(100, 183, 178, 0.1);
  }

  tbody tr:hover {
    /* background-color: rgba(100, 183, 178, 0.5); */
    background-color: rgba(100, 183, 178, 0.3);
  }

  // ----------------------------------------------------------------------

  thead {
    /* background-color: rgba(13, 138, 114, 0.4); */
    background-color: #0d8a71d0;
    color: #fff !important;
  }

  .table {
    color: white !important;
  }

  thead tr {
    background-color: rgba(13, 138, 114, 0.4);
    color: #fff !important;
  }

  thead tr th {
    background-color: rgba(13, 138, 114, 0.4);
    color: #fff !important;
  }
}


.custom_form_style {
  .search_pinfl_box {
      background-color: #fafafa;
      border: 1px solid #8ec5ef;
      padding: 8px 20px 8px 8px;
      border-radius: 4px;
      margin-bottom: 14px;
  }

  .border_col {
      border: 1px solid #e7eaee;
      border-radius: 4px;
      padding: 16px 8px;
      margin: 10px 0;
  }

  .ant-upload.ant-upload-select-picture-card {
      width: 100%;
      height: 166px;
      max-width: 150px;
  }

  .ant-upload-list-picture-card-container {
      display: inline-block;
      width: 100%;
      height: 166px;
      max-width: 150px;
      margin: 0 8px 8px 0;
  }

  .ant-input {
      padding: 3px 10px;
      color: #6e6d7a;
      font-size: 13px;
      line-height: 1.5715;
      background-color: #fff;
      border: 1px solid #dadce0;
      border-radius: 4px;
      text-shadow: 0 0 #6e6d7a;
      letter-spacing: 0.2px;
  }
  .ant-input[placeholder] {
      font-size: 13px;
  }

  .ant-form-item-label {
      padding: 0 0 2px;
      text-align: left;
  }

  .ant-form-item {
      color: rgba(0, 0, 0, 0.85);
      font-size: 13px;
      margin-bottom: 12px;
  }

  .ant-select {
      font-size: 14px;
      letter-spacing: 0.2px;
      font-weight: 400;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 30px !important;
      padding: 0px 10px;
      color: black !important;
      letter-spacing: 0.2px;
      // text-shadow: 0 0 #6e6d7a;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      font-size: 13px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      padding: 0;
      line-height: 27px;
      transition: all 0.3s;
  }

  .ant-picker {
      color: #6e6d7a !important;
      font-size: 13px;
      padding: 3px 11px 3px;
      background: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      letter-spacing: 0.2px;
  }

  .ant-picker-input > input {
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 4px 11px;
      color: #6e6d7a;
      font-size: 13px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      transition: all 0.3s;
      flex: auto;
      min-width: 1px;
      height: auto;
      padding: 0;
      background: transparent;
      border: 0;
      text-shadow: 0 0 #6e6d7a;
      letter-spacing: 0.2px;
  }

  .ant-upload {
      .ant-btn {
          background-image: none;
          border: 1px solid transparent;
          height: 28px;
          padding: 3px 15px;
          font-size: 13px;
          border-radius: 4px;
          color: rgba(0, 0, 0, 0.85);
          border-color: #d9d9d9;
          background: #fff;
      }
  }

  .ant-form-item-explain {
      // display: none;
  }
}

.p_12{
  padding: 12px !important;
}
.m_12{
  margin: 12px !important;
}
.mb_12{
  margin-bottom: 12px;
}
.mt_12{
  margin-top: 12px;
}
.ms_12{
  margin-left: 12px;
}
.me_12{
  margin-right: 12px;
}
.pb_12{
  padding-bottom: 12px;
}
.pt_12{
  padding-top: 12px;
}
.ps_12{
  padding-left: 12px;
}
.pe_12{
  padding-right: 12px;
}