.content {
    .content_header {
        display: flex;
        align-items: center;
        justify-content: end;

        .add_btn {
            border: none;
            font-size: 14px;
            padding: 8px 28px;
            border-radius: 4px;
            cursor: pointer;
            background-color: #EEF1F6;
            color: grey;
            &:not(:last-child) {
                margin-right: 10px;
            }

            &:focus {
                outline: none;
            }

            &:active {
                outline: none;
            }

            i {
                margin-right: 6px;
                font-size: 14px;
            }
        }

        .sort_btn {
            border: none;
            font-size: 14px;
            padding: 8px 16px;
            border-radius: 4px;
            cursor: pointer;
            background-color: #EEF1F6;
            color: grey;
            margin-right: 10px;
            &:focus {
                outline: none;
            }

            &:active {
                outline: none;
            }

        }

        .sort_btn_cancel {
            border: none;
            font-size: 14px;
            padding: 7px 14px;
            border-radius: 4px;
            cursor: pointer;
            background-color: #FF0000;
            color: white;
            margin-right: 10px;
            float: right;
            &:focus {
                outline: none;
            }

            &:active {
                outline: none;
            }

        }

        .sort_btn_save {
            border: none;
            font-size: 14px;
            padding: 7px 14px;
            border-radius: 4px;
            cursor: pointer;
            background-color: #00FF00;
            color: white;
            margin-right: 10px;
            float: right;
            &:focus {
                outline: none;
            }

            &:active {
                outline: none;
            }

        }

        .add_btn_save {
            float: right;
        }
    }

    .uploading_card {
        box-shadow: 0 2px 3px 0 rgba($color: #000000, $alpha: 0.2);
        padding: 10px;

        .uploading_card_header {
            display: flex;
            border-bottom: 1px solid rgba(0, 0, 0, .2);
            justify-content: space-between;
            padding: 10px 0;

            div:nth-child(2) {
                button {
                    margin-left: 8px;
                    background-color: #EEF1F6;
                    border: none;
                    color: grey;
                    padding: 6px 12px;
                    cursor: pointer;
                    border-radius: 4px;

                    &:focus {
                        outline: none;
                    }

                    &:disabled {
                        color: red;
                    }

                    &:active {
                        background-color: #bbbfc7;
                    }
                }
            }
        }

        .card_item {
            width: 250px;
            margin: 30px 10px 0 20px;

            .card_item_header {
                >div:nth-child(1) {
                    display: flex;
                    justify-content: space-between;

                    label {
                        padding: 4px 14px;
                        display: inline-block;
                        background-color: #EEF1F6;
                        border-radius: 4px;
                        color: grey;
                        cursor: pointer;

                        &:hover {
                            background-color: #ced5e0;
                        }
                    }

                    select {
                        width: 120px;
                        margin-right: 6px;
                        color: grey;

                        &:focus {
                            outline: none;
                        }
                    }

                    .remove_child_item {
                        background-color: #EEF1F6;
                        font-size: 16px;
                        border: none;
                        cursor: pointer;

                        &:hover {
                            background-color: #ced5e0;
                        }
                    }
                }

                >div:nth-child(2) {
                    margin: 10px 0;

                    input {
                        width: 100%;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }
    }
}

.controller {
    button {
        margin-left: 8px;
        background-color: #EEF1F6;
        border: none;
        color: grey;
        padding: 6px 12px;
        cursor: pointer;
        border-radius: 4px;

        &[type=submit] {
            background-color: #0d8a72;
            color: white;
        }

        &:focus {
            outline: none;
        }

        &:disabled {
            color: red;
        }

        &:active {
            background-color: #bbbfc7;
        }
    }
}