.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
}
.dashboard_card_icon {
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 30px !important;
  color: rgba(13, 138, 114, .9);
  & > path {
    stroke: #0d8a71!important;
  }
}
.forBorder {
  border: 1px solid #f0f0f0;
}

.forLessons {
  & > .ant-card-body {
    padding: 0;
    & .ant-col {
      &:nth-child(2n) {
        & .ant-card {
          margin-left: 0.25rem;
        }
      }
      &:nth-child(2n+1){
        & .ant-card {
          margin-right: 0.25rem;
        }
      }
      & .ant-card {
        height: 100%;
        & .ant-card-head {
          min-height: unset;
          & .ant-card-head-title {
            padding: 0;
            padding-left: 0.5rem;
            margin: .5rem 0;
            border-radius: .2rem;
            background-color: #0d8a71;
            color: #fff;
          }
        }
        & .ant-card-body {
          position: relative;
          & .raspisaniye {
            font-size: 16px;
            margin-bottom: .25rem;
          }
          & .dotsAction {
            position: absolute;
            right: 20px;
            top: 20px;
            & svg {
              width: 20px;
              height: 20px;
            }
          }
          & .delChan {
            position: absolute;
            right: 40px;
            top: 20px;
            background-color: #0d8a71;
            padding: 0px 5px;
            border-radius: 5px;
            color: #fff;
            height: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .2s;
            overflow: hidden;
            &.open {
              height: 37px;
              padding: 2.5px 5px;
            }
            & > div {
              width: 30px;
              padding: 0 5px;
              & svg {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}