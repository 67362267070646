.kazus-question-remove-button {
    position: absolute;
    right: 20px;
    top: 35px;
    z-index: 200;
}

.question-persent-input {
    position: absolute;
    right: 12px;
    top: -5px;
    width: 170px;
    z-index: 200;

    .question-persent-input-label::before {
        content: "*";
        color: red;
        padding-right: 2px;
        font-size: 15px;
    }

    .error-persent-value {
        border: 1px solid red;
    }
}

.question-container-custom {
    .question-info-left {
        overflow: hidden;
        font-size: 14px;
        border-right: 1px solid rgba($color: #000000, $alpha: .5);
    }

    .question-content-right {
        transition: width 300ms ease;

        .view-question-page {
            font-size: 14px;

            .cazus-sub-question-container {

                p,
                div,
                span {
                    font-size: 14px !important;
                    line-height: 18px;
                }
            }

            .cazus-text-container {

                p,
                div,
                span {
                    font-size: 14px !important;
                    line-height: 18px;
                }
            }
        }
    }
}


.question_main_view {
    text-align: justify;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums !important;
    font-feature-settings: 'tnum', "tnum" !important;

    p,
    div,
    span {
        font-variant: tabular-nums !important;
        font-feature-settings: 'tnum', "tnum" !important;
    }
}


.question_img_box {
    width: 100%;
    height: auto;

    &>img {
        width: 100% !important;
        height: 100%;
    }
}


.text_size_question_12 {
    font-size: 12px;

    p,
    div,
    span {
        font-size: 12px !important;
        line-height: 16px;
    }
}

.text_size_question_13 {
    font-size: 13px;

    p,
    div,
    span {
        font-size: 13px !important;
        line-height: 17px;
    }
}

.text_size_question_14 {
    font-size: 14px;

    p,
    div,
    span {
        font-size: 14px !important;
        // line-height: 21px;
    }
}

.text_size_question_15 {
    font-size: 15px;

    p,
    div,
    span {
        font-size: 15px !important;
        line-height: 19px;
    }
}

.text_size_question_16 {
    font-size: 16px;

    p,
    div,
    span {
        font-size: 16px !important;
        line-height: 20px;
    }
}

.text_size_question_17 {
    font-size: 17px;

    p,
    div,
    span {
        font-size: 17px !important;
        line-height: 21px;
    }
}

.text_size_question_18 {
    font-size: 18px;

    p,
    div,
    span {
        font-size: 18px !important;
        line-height: 22px;
    }
}

.text_size_question_19 {
    font-size: 19px;

    p,
    div,
    span {
        font-size: 19px !important;
        line-height: 23px;
    }
}

.text_size_question_20 {
    font-size: 20px;

    p,
    div,
    span {
        font-size: 20px !important;
        line-height: 24px;
    }
}

.text_size_question_21 {
    font-size: 21px;

    p,
    div,
    span {
        font-size: 21px !important;
        line-height: 25px;
    }
}

.text_size_question_22 {
    font-size: 22px;

    p,
    div,
    span {
        font-size: 22px !important;
        line-height: 26px;
    }
}

.text_size_question_23 {
    font-size: 23px;

    p,
    div,
    span {
        font-size: 23px !important;
        line-height: 27px;
    }
}

.text_size_question_24 {
    font-size: 24px;

    p,
    div,
    span {
        font-size: 24px !important;
        line-height: 28px;
    }
}

.text_size_question_25 {
    font-size: 25px;

    p,
    div,
    span {
        font-size: 25px !important;
        line-height: 29px;
    }
}

.text_size_question_26 {
    font-size: 26px;

    p,
    div,
    span {
        font-size: 26px !important;
        line-height: 30px;
    }
}

.text_size_question_27 {
    font-size: 27px;

    p,
    div,
    span {
        font-size: 27px !important;
        line-height: 31px;
    }
}

.text_size_question_28 {
    font-size: 28px;

    p,
    div,
    span {
        font-size: 28px !important;
        line-height: 32px;
    }
}

.text_size_question_29 {
    font-size: 29px;

    p,
    div,
    span {
        font-size: 29px !important;
        line-height: 33px;
    }
}

.text_size_question_30 {
    font-size: 30px;

    p,
    div,
    span {
        font-size: 30px !important;
        line-height: 34px;
    }
}

.questions-to-print-container {
    display: none;
}

@media print {
    .questions-to-print-container {
        display: block;
    }

    @page {
        size: auto;
        margin: 5mm 5mm 5mm 15mm;
    }

}