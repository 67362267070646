.disable_exam::after {
    content: "";
    text-align: center;
    color:green;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #fff, $alpha: 0.7);
}


@media print {
   
    .question_part_check > table >td, th {
        border: 1px solid black !important;
     }
}


.exam_max_score_checking {
    color: #096DE0;
    height: 30px;
    display: block; 
    font-size: 14px; 
    line-height: 30px;
    text-align: center;
}

.description_tools_style {
    display: flex;

    @media(max-width:1400px){
        display: block;
    }
}