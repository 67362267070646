.conculision_divider {
    font-size: 14px !important;
    color:#000;
    margin: 0 0 4px 0 !important;
}


.question_body {

    color: #5F6368 !important;
    text-align: justify !important; 
    font-size: 16px !important;
    line-height: 16px !important;

    span ,strong, p , div, b {
        color: #5F6368 !important;
        text-align: justify !important; 
        font-size: 16px !important;
        line-height: 16px !important;
    }

}