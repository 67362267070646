.custom_form_label {
    font-size: 13px;
    font-weight: 400;
    color: #8c8c8c;
    margin-right: 10px;
}
.custom_form_label_crud {
    font-size: 13px;
    font-weight: 500;
    color: #72777D;
    margin-right: 10px;
    letter-spacing: .01785714em;
    line-height: 1.25rem;
    white-space: nowrap;
    // font-family: "Product Sans",Arial,sans-serif !important;
}